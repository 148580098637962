import React, { FC } from 'react'
import { get, map } from 'lodash'
import { Col, Row } from 'antd'
import { Field, WrappedFieldProps, WrappedFieldMetaProps, WrappedFieldInputProps } from 'redux-form'
import { useSelector } from 'react-redux'
import { useMediaQuery } from 'react-responsive'
import MaskedInput from '../../packages/react-maskedinput'
import { ReactComponent as ErrorIcon } from '../../assets/icons/error.svg'
import { TELEPHONE_PREFIXES, formFieldID } from '../../utils/helpers'
import SelectField from './SelectField'

declare module 'react' {
	function forwardRef<T, P = {}>(
		render: (props: P, ref?: React.Ref<T>) => React.ReactElement | null
	): (props: P & React.RefAttributes<T>) => React.ReactElement | null
}

export interface IPhoneNumberField {
	name?: string
	label?: string
	className?: string
	formName: string
	meta: Partial<WrappedFieldMetaProps>
	input: Partial<WrappedFieldInputProps>
}

export interface IPrefixSelect {
	width?: number
	placeholder?: string
	prefixOptions?: any
	className?: string
}

const PrefixSelect: FC<IPrefixSelect & WrappedFieldProps> = ({ input, prefixOptions, placeholder, meta }) => {
	return (
		<SelectField
			input={input}
			customOptions
			onChange={(value: any) => input.onChange(value)}
			placeholder={placeholder}
			meta={meta}
			options={prefixOptions}
			defaultValue={{
				label: prefixOptions[0].label,
				value: prefixOptions[0].value,
				flag: prefixOptions[0].flag
			}}
		/>
	)
}

const NumberInput = React.forwardRef(({ input }: WrappedFieldProps, ref?: React.ForwardedRef<MaskedInput>) => (
	<MaskedInput
		className={'text-17 form-field p-4 bg-transparent rounded-sm'}
		{...input}
		mask={'111 111 111'}
		style={{ width: '100%' }}
		placeholder={'--- --- ---'}
		placeholderChar={'-'}
		onChange={(e: any) => input.onChange(e.target.value)}
		ref={ref}
	/>
))

const PhoneNumberField = React.forwardRef(({ label, formName, meta, input }: IPhoneNumberField) => {
	const { form = 'form', error = undefined, touched = undefined } = meta
	const isMobile = useMediaQuery({ maxWidth: 991 })
	const { name = 'field' } = input
	const phoneNumberValue = useSelector((state) => get(state, `form.${formName}.values.phoneNumber`))
	const id = formFieldID(form, name)

	const prefixOptions = map(TELEPHONE_PREFIXES, (country) => ({
		label: country.prefix,
		value: country.prefix,
		flag: country.flag
	}))

	return (
		<div className={'mb-[16px]'}>
			{label && (
				<label htmlFor={id} className={'form-field-label'}>
					{label}
				</label>
			)}
			<Row className={`bg-gray-100 rounded-sm phone_number border border-solid ${error && touched && 'error'}`}>
				<Col sm={isMobile ? undefined : 6} span={isMobile ? undefined : 8} style={{ borderRight: '1px solid #d7d7d7' }}>
					<Field name={'phonePrefix'} component={PrefixSelect} phoneNumberTouched={phoneNumberValue} prefixOptions={prefixOptions} />
				</Col>
				<Col sm={isMobile ? undefined : 18} span={isMobile ? undefined : 16} className={'flex flex-row items-center'}>
					<Field name={'phoneNumber'} component={NumberInput} />
					<span className={'form-field-item-suffix'}>{error && touched && <ErrorIcon />}</span>
				</Col>
			</Row>
			{error && touched && <span className={'form-field-message'}>{error}</span>}
		</div>
	)
})

export default PhoneNumberField
