import i18n from 'i18next'
import Backend from 'i18next-chained-backend'
import XHR from 'i18next-http-backend'
import LocalStorageBackend from 'i18next-localstorage-backend'
import { initReactI18next } from 'react-i18next'
import { APP_LANGUAGES, DEFAULT_APP_LANGUAGE, NAMESPACE } from './enums'

import 'dayjs/locale/en'

i18n
	.use(Backend)
	.use(initReactI18next)
	.init({
		backend: {
			backendOptions: [
				{
					expirationTime: process.env.NODE_ENV === 'development' ? 0 : 0, // 7 * 24 * 60 * 60 * 1000 = 1 week
					prefix: 'i18next_res_'
				},
				{
					loadPath: '/locales/{{lng}}/{{ns}}.json',
					queryStringParams: { v: process.env.REACT_APP_VERSION }
				}
			],
			backends: [
				LocalStorageBackend, // primary
				XHR // fallback
			]
		},
		load: 'languageOnly',
		supportedLngs: Object.values(APP_LANGUAGES),
		fallbackLng: DEFAULT_APP_LANGUAGE,
		ns: Object.values(NAMESPACE),
		defaultNS: 'keep-empty',
		nsSeparator: ':',
		keySeparator: '|',
		react: {
			bindI18n: 'languageChanged loaded',
			bindI18nStore: 'added removed',
			nsMode: 'default',
			useSuspense: true
		},
		interpolation: {
			escapeValue: false
		},
		debug: process.env.NODE_ENV === 'development'
	})

export default i18n
