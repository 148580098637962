import { Button, Col, Drawer, Row } from 'antd'
import cx from 'classnames'
import { map } from 'lodash'
import { FC, PropsWithChildren, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useMediaQuery } from 'react-responsive'
import { NavLink, useLocation } from 'react-router-dom'
import { reset } from 'redux-form'
import { ReactComponent as MenuIcon } from '../assets/icons/menu.svg'
import { ReactComponent as PersonIcon } from '../assets/icons/person.svg'
import { ReactComponent as PlusIcon } from '../assets/icons/plus.svg'
import BasicModal from '../atoms/BasicModal'
import UserAvatar from '../atoms/UserAvatar'
import { employeesListFilterDefaultState } from '../pages/employees/list/EmployeesListFilter'
import { adminsCreate } from '../redux/admins/adminsActions'
import { employeesCreate, employeesLoadList } from '../redux/employees/employeesActions'
import { FORM } from '../utils/enums'
import CreateAdminForm, { ICreateAdminFormValues } from './admin/CreateAdminForm'
import CreateEmployeeForm, { ICreateEmployeeFormValues } from './admin/CreateEmployeeForm'
import LogoutPopover from './shared/LogoutPopover'

type IAdminLayout = PropsWithChildren

const AdminLayout: FC<IAdminLayout> = ({ children }) => {
	const { t } = useTranslation()
	const location = useLocation()
	const dispatch = useDispatch()
	const isMobile = useMediaQuery({ maxWidth: 991 })
	const isDesktop = useMediaQuery({ minWidth: 992 })

	const [mobileNavDrawerVisible, setMobileNavDrawerVisible] = useState<boolean>(false)
	const [createAdminModalVisible, setCreateAdminModalVisible] = useState<boolean>(false)
	const [createAdminFormSubmitSuccess, setCreateAdminFormSubmitSuccess] = useState<boolean>(false)
	const [createEmployeeModalVisible, setCreateEmployeeModalVisible] = useState<boolean>(false)
	const [createEmployeeFormSubmitSuccess, setCreateEmployeeFormSubmitSuccess] = useState<boolean>(false)

	const handleCreateAdminFormSubmit = async (values: ICreateAdminFormValues) => {
		await dispatch(adminsCreate(values, () => setCreateAdminFormSubmitSuccess(true)))
	}

	const handleCreateAdminModalClose = () => {
		setCreateAdminModalVisible(false)
		setCreateAdminFormSubmitSuccess(false)
		setMobileNavDrawerVisible(false)
		dispatch(reset(FORM.ADMIN_CREATE))
	}

	const handleCreateEmployeeFormSubmit = async (values: ICreateEmployeeFormValues) => {
		await dispatch(employeesCreate(values, () => setCreateEmployeeFormSubmitSuccess(true)))
	}

	const handleCreateEmployeeModalClose = () => {
		setCreateEmployeeModalVisible(false)
		setCreateEmployeeFormSubmitSuccess(false)
		setMobileNavDrawerVisible(false)
		dispatch(reset(FORM.EMPLOYEE_CREATE))
		if (location.pathname === t('paths:employees')) {
			dispatch(employeesLoadList(employeesListFilterDefaultState))
		}
	}

	const navLinks = map(
		[
			{ label: t('loc:admin|Withdrawals'), to: t('paths:withdrawals') },
			{ label: t('loc:admin|Employees'), to: t('paths:employees') }
		],
		({ label, to }, index) => (
			<li key={`header-link-${index}`}>
				<NavLink
					to={to}
					onClick={() => isMobile && setMobileNavDrawerVisible(false)}
					className={({ isActive }) =>
						cx(
							'text-16 font-600 leading-none text-body',
							{ 'flex p-4': isMobile },
							{ 'bg-purple-light': isMobile && isActive },
							{ 'inline-flex p-2 border-solid border-b-2px border-transparent hover:text-purple-base': isDesktop },
							{ 'lg:text-purple-base lg:border-b-purple-base': isDesktop && isActive }
						)
					}
				>
					{label}
				</NavLink>
			</li>
		)
	)

	const menuButtons = (
		<>
			<Button type={'primary'} shape={'round'} size={'small'} onClick={() => setCreateAdminModalVisible(true)}>
				<PlusIcon />
				{t('loc:admin|Admin')}
			</Button>
			<Button type={'primary'} shape={'round'} size={'small'} onClick={() => setCreateEmployeeModalVisible(true)}>
				<PlusIcon />
				{t('loc:admin|Employee')}
			</Button>
		</>
	)

	return (
		<>
			{/* Header */}
			<header className={'fixed top-0 left-0 right-0 z-10 bg-white shadow-md py-6 px-4 lg:px-8 xl:px-5% xxl:px-10%'}>
				<Row justify={'space-between'} align={'middle'}>
					<Col xs={6} lg={0} hidden={isDesktop} className={'inline-flex'}>
						<Button type={'text'} icon={<MenuIcon className={'text-24'} />} onClick={() => setMobileNavDrawerVisible(!mobileNavDrawerVisible)} />
					</Col>
					<Col xs={12} lg={5} className={'inline-flex justify-center lg:justify-start'}>
						<h1 className={'text-26 font-700'}>{t('loc:general|WageNow')}</h1>
					</Col>
					<Col xs={0} lg={7} hidden={isMobile}>
						<ul className={'inline-flex items-center gap-6'}>{navLinks}</ul>
					</Col>
					<Col xs={6} lg={12} className={'inline-flex justify-end items-center gap-4 lg:gap-8'}>
						{isDesktop && <menu className={'inline-flex items-center gap-2'}>{menuButtons}</menu>}
						<LogoutPopover>{isMobile ? <PersonIcon className={'text-24 text-gray-500'} /> : <UserAvatar showName={true} />}</LogoutPopover>
					</Col>
				</Row>
			</header>
			{/* Content */}
			<section className={'flex-grow-1 mt-[5.5rem] bg-purple-light py-4 lg:py-12 px-4 lg:px-8 xl:px-5% xxl:px-10%'}>{children}</section>
			{/* Mobile nav drawer */}
			{isMobile && (
				<Drawer
					placement={'left'}
					width={'80%'}
					visible={mobileNavDrawerVisible}
					onClose={() => setMobileNavDrawerVisible(false)}
					destroyOnClose={true}
					className={'mobile-nav-drawer'}
				>
					<ul className={'flex flex-col'}>{navLinks}</ul>
					<menu className={'flex flex-col gap-2 p-4'}>{menuButtons}</menu>
				</Drawer>
			)}
			{/* Modals */}
			<BasicModal visible={createAdminModalVisible} onClose={() => setCreateAdminModalVisible(false)} width={'30rem'}>
				<CreateAdminForm submitSuccess={createAdminFormSubmitSuccess} handleModalClose={handleCreateAdminModalClose} onSubmit={handleCreateAdminFormSubmit} />
			</BasicModal>
			<BasicModal visible={createEmployeeModalVisible} onClose={() => setCreateEmployeeModalVisible(false)} width={'30rem'}>
				<CreateEmployeeForm
					submitSuccess={createEmployeeFormSubmitSuccess}
					handleModalClose={handleCreateEmployeeModalClose}
					onSubmit={handleCreateEmployeeFormSubmit}
				/>
			</BasicModal>
		</>
	)
}

export default AdminLayout
