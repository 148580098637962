import { Switch, SwitchProps } from 'antd'
import cx from 'classnames'
import { FC } from 'react'
import { WrappedFieldInputProps, WrappedFieldMetaProps } from 'redux-form'
import { formFieldID } from '../../utils/helpers'

interface ISwitchField extends SwitchProps {
	label?: string
	input: Partial<WrappedFieldInputProps>
	meta: Partial<WrappedFieldMetaProps>
	inputClassName?: string
}

const SwitchField: FC<ISwitchField> = ({ label, disabled, className, style, input, meta, inputClassName, ...props }) => {
	const { name = 'field', checked = false, onChange = undefined } = input
	const { form = 'form', error = undefined, touched = false } = meta

	const id = formFieldID(form, name)

	return (
		<div className={cx('form-field', className)} style={style}>
			{label && (
				<label htmlFor={id} className={'form-field-label'}>
					{label}
				</label>
			)}
			<div className={cx('form-field-item', { error: error && touched })}>
				<Switch
					id={id}
					disabled={disabled}
					defaultChecked={checked}
					onChange={onChange}
					className={cx(inputClassName, { error: error && touched })}
					style={{ backgroundColor: '#d3d3d3' }}
					{...props}
				/>
			</div>
			{error && touched && <span className={'form-field-message'}>{error}</span>}
		</div>
	)
}

export default SwitchField
