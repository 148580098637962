import * as Sentry from '@sentry/react'
import { Integrations as TracingIntegrations } from '@sentry/tracing'
import 'core-js/stable'
import dayjs from 'dayjs'
import { createRoot } from 'react-dom/client'
import 'regenerator-runtime/runtime'
import './styles/main.sass'

import 'dayjs/locale/en-gb'
import 'dayjs/locale/sk'

import { init as initApm } from '@elastic/apm-rum'

import App from './App'
import ErrorBoundary from './ErrorBoundary'
import { register } from './registerServiceWorker'

dayjs.locale('en-gb')

let env = process.env.REACT_APP_ENV || 'production'
if (typeof window !== 'undefined') {
	if (window.location.hostname.includes('localhost')) {
		env = 'dev'
	} else if (window.location.hostname.includes('staging')) {
		env = 'staging'
	}
}

initApm({
	serviceName: 'ewa-web',
	serverUrl: 'https://apm.wagenow.sk:443',
	environment: env,
	breakdownMetrics: true
})

Sentry.init({
	enabled: process.env.NODE_ENV !== 'development',
	release: process.env.REACT_APP_VERSION,
	integrations: [new TracingIntegrations.BrowserTracing()] as any,
	// eslint-disable-next-line no-underscore-dangle
	environment: window?.__RUNTIME_CONFIG__?.REACT_APP_SENTRY_ENV ? window.__RUNTIME_CONFIG__.REACT_APP_SENTRY_ENV : process.env.REACT_APP_SENTRY_ENV,
	// eslint-disable-next-line no-underscore-dangle
	dsn: window?.__RUNTIME_CONFIG__?.REACT_APP_SENTRY_DSN ? window.__RUNTIME_CONFIG__.REACT_APP_SENTRY_DSN : process.env.REACT_APP_SENTRY_DSN,
	tracesSampleRate: 0.05
})

register()

const root = createRoot(document.body.querySelector('#root') as HTMLElement)
root.render(
	<ErrorBoundary>
		<App />
	</ErrorBoundary>
)
