import { Select, SelectProps } from 'antd'
import cx from 'classnames'
import { WrappedFieldInputProps, WrappedFieldMetaProps } from 'redux-form'
import { FC, memo } from 'react'
import { map } from 'lodash'
import { ReactComponent as ErrorIcon } from '../../assets/icons/error.svg'
import { formFieldID } from '../../utils/helpers'

interface ISelectField extends SelectProps {
	label?: string
	input: Partial<WrappedFieldInputProps>
	meta: Partial<WrappedFieldMetaProps>
	inputClassName?: string
	customOptions?: boolean
}

export const { Option } = Select

const SelectField: FC<ISelectField> = ({ label, placeholder, className, style, input, meta, inputClassName, options, customOptions = false, ...props }) => {
	const { name = 'field', value = undefined, onChange = undefined } = input
	const { form = 'form', error = undefined, touched = false } = meta

	const id = formFieldID(form, name)

	return (
		<div className={cx('form-field', className)} style={style}>
			{label && (
				<label htmlFor={id} className={'form-field-label'}>
					{label}
				</label>
			)}
			<div className={cx('form-field-item', { error: error && touched })}>
				{customOptions ? (
					<Select
						id={id}
						placeholder={placeholder}
						value={value || undefined}
						defaultValue={placeholder ? undefined : value || undefined}
						onChange={onChange}
						className={cx(inputClassName, { error: error && touched })}
						{...props}
					>
						{map(options, (option) => (
							<Option key={option.value} value={option.value} label={option.label}>
								{option.flag && <img src={option.flag} alt={option.label as string} className={'mr-[16px] rounded-xs hidden sx:block sm:block'} />}
								<span>{option.label}</span>
							</Option>
						))}
					</Select>
				) : (
					<Select
						id={id}
						placeholder={placeholder}
						value={value || undefined}
						defaultValue={placeholder ? undefined : value || undefined}
						onChange={onChange}
						options={options}
						className={cx(inputClassName, { error: error && touched })}
						{...props}
					/>
				)}

				<span className={'form-field-item-suffix'}>{error && touched && <ErrorIcon />}</span>
			</div>
			{error && touched && <span className={'form-field-message'}>{error}</span>}
		</div>
	)
}

export default memo(SelectField)
